import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar} from "./sessionStorage_controller";
import {get_modalities} from "./rentaContractModalityTariff_controller";
import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es";
import {english} from "flatpickr/dist/l10n/default";
import {set_locale} from './localize_controller'
import {create_array_for_product} from './b2b_controller'
import bootbox from "bootbox";
import {validate_emails} from "./verificaciones_controller";
import {unblockBody} from "./blockUI_controller";

let categories = [];
let active_categories = [];
let date_disabled = [];
let flatpick_lang;
let lang_value = '';
let location_value = '';
let responsible_value = '';

const date = new Date();
let [year, month, day, hour, minutes, seconds] = [
    date.getFullYear(),
    (`${date.getMonth() + 1}`.length > 1) ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
];

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
}

function adjust_time() {
    if(date.getMinutes() > 1){
        if(date.getHours() < 23){
            hour = parseInt(hour) + 1
            minutes = 0
        }else{
            day = parseInt(day) + 1
            hour = 23
            minutes = 50
        }
    }
    return true;
}

export let preferences = (text, type) => new Promise((resolve, reject) => {
    $.ajax({
        url: location.origin + `/${sessionStorage.getItem('locale')}/config/get_preference`, data: {
            format: 'json', type: type, text: text
        }, type: 'GET', success: function (data) {
            // resolve(data[0]);
        }
    })
})

let categories_details = (target) => new Promise((resolve, reject) => {
    categories.services.map((child, key) => {
        if (child !== null) {
            if (child.code === target) {
                resolve(child);
            }
        }
    });
})

export function modifiedKey(key) {
    let v = key;
    v = v.split("_");
    let last = v.pop();
    let nameCapitalized = last.charAt(0).toUpperCase() + last.slice(1);
    v.push(nameCapitalized);
    return v.join('');
}

let disable_dates = () => new Promise((resolve, reject) => {
    $.ajax({
        url: location.origin + "/admin/disable_dates/disabled", data: {
            format: "json"
        }, type: "GET", success: function (data) {
            resolve(data);
        }
    })
})

let verify_season = (date) => new Promise((resolve, reject) => {
    $.ajax({
        url: location.origin + "/verify_season", data: {
            date: date, category: create_array_for_product("category", '', 'show')
        }, type: "GET", success: function (data) {
            if (data.success) {
                resolve(data.data);
            } else {
                console.log(data);
            }
        }

    })
})

let user_data_as_comment = () => {
    $.ajax({
        url: `${location.origin}/${sessionStorage.getItem('locale')}/authenticated_user`,
        data: {},
        type: 'GET',
        success: function (user) {
            if (user.success) {
                responsible_value = `Nombre: ${user.username} <br> Apellidos: ${user.lastname} <br> CI ó Pasaporte: ${user.passport} <br> Email: ${user.email} <br> Teléfono: ${user.phone} <br> Dirección: ${user.address}`;
                make_comment();
            } else {
                $('#link_login_modal').trigger('click');
                toastr.info('Debe autenticarse para continuar con la reserva este producto', 'Información');
                $('#bus_btn').prop('disabled', true);
                $('.close_form').trigger('click');
            }
        }
    })
}
let make_comment = () => {
    create_array_for_product('comment', '', 'delete');
    let text = '';
    setTimeout(() => {
        if (!location_value.isEmpty() && !lang_value.isEmpty() && !responsible_value.isEmpty()) {
            text = `Lugar de recogida: ${location_value} <br> Hora de recogida: ${lang_value} <br> Datos del responsable: <br> ${responsible_value}`;
        } else {
            if (!location_value.isEmpty()) {
                text = `Lugar de recogida: ${location_value} <br>`;
            }
            if (!lang_value.isEmpty()) {
                text = `Hora de recogida: ${lang_value} <br>`;
            }
            if (!responsible_value.isEmpty()) {
                text = `Datos del responsable: <br> ${responsible_value} <br>`;
            }
        }
        create_array_for_product('comment', text);
    }, 500)

}
export default class extends Controller {
    static targets = ["siteName", "ticketLogo", "textDestination", "collectPlace", "deliveryPlace", "category", "textSearchMark", "textSearchModel", "dateCollect", "timeCollect", "dateDelivery", "timeDelivery", "textButton", "siteNameFooter", "contactNumber", "contactNumberTwo", "contactEmail", "contactEmailTwo", "contactAddress", "staticPage", "formText", "manyDrivers", "driversCount", "supplementInsurance", "busResponsible"];

    initialize() {
        adjust_time();
        let principal_background = $('#principal-background-image');
        let principal_target_carousel = $('#principalCarouselPresentation');

        if (principal_target_carousel.length > 0) {
            principal_target_carousel.on('slide.bs.carousel', function onSlide(e) {
                let dataset = e.relatedTarget.dataset;
                if (dataset.hasOwnProperty('background')) {
                    principal_background[0].style.backgroundImage = `url('${dataset.background}')`;
                }
            })
        }
    }

    connect() {

        $('input.hotel_hijo').keydown(function (event) {
            if ([38, 40].indexOf(event.keyCode) > -1) {
                event.preventDefault();
            }
        });

        $('#form_container').on('click', function (e) {
            if (e.target.id === 'form_container') {
                let form = $(e.target).find('.form');
                if (e.target.classList.contains('active')) {
                    form[0].classList.add('animate_form');
                    setTimeout(() => {
                        form[0].classList.remove('animate_form');
                    }, 500)
                }
            }


        })

        set_locale().then(locale => {
            switch (I18n.locale) {
                case 'es':
                    flatpick_lang = Spanish;
                    break;
                case 'en':
                    flatpick_lang = english;
                    break;
            }
            const context = this;
            let destino, recogida, entrega;
            let select_destination = $('#destination')
            let destination_car_lux = $('#destination_car_lux').select2({theme: "bootstrap"});
            let destination_bikes = $('#destination_bikes')
            let select_collect = $('#collect_place');
            let select_delivery = $('#delivery_place');
            let form_type_send = $('#search_form_type');
            let collect = select_collect.select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_office')
            });
            let collect_place_car_lux = $('#collect_place_car_lux').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_office')
            });
            let collect_place_bikes = $('#collect_place_bikes').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_office')
            });
            let delivery = select_delivery.select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_office')
            });
            let delivery_place_car_lux = $('#delivery_place_car_lux').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_office')
            });
            let destination = select_destination.select2({theme: "bootstrap"});
            let category = $('#category').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category')
            });
            let category_car_lux = $('#category_car_lux').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category')
            });
            let category_bikes = $('#category_bikes').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category')
            });
            let modality_bikes = $('#modality_bikes').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_modality')
            });
            let category_bustour = $('#category_bustour').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category')
            });
            let drivers = $('#drivers').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_drivers')
            });
            let drivers_car_lux = $('#drivers_car_lux').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_drivers')
            });
            let drivers_bikes = $('#drivers_bikes').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_drivers')
            });
            let search_mark = $('#search_mark').select2({theme: "bootstrap"});
            let search_model = $('#search_model').select2({theme: "bootstrap"});
            let has_drivers = $('#has_drivers');
            let has_drivers_car_lux = $('#has_drivers_car_lux');
            let has_drivers_bikes = $('#has_drivers_bikes');
            let supplement_insurance = $('#supplement_insurance');
            let supplement_insurance_car_lux = $('#supplement_insurance_car_lux');
            let supplement_insurance_bikes = $('#supplement_insurance_bikes');
            let preferences = {};

            // SHUTTLE
            let select_destination_shuttle = $('#destination_shuttle');
            let date_collect_shuttle = $('#date_collect_shuttle').prop('disabled', 'true');
            let amount_shuttle = $('#amount_shuttle').prop('disabled', 'true');
            //END SHUTTLE

            // BUSTOUR
            let select_destination_bustour = $('#destination_bustour');
            let date_collect_bustour = $('#date_collect_bustour').prop('disabled', 'true');
            let amount_bustour = $('#amount_bustour').prop('disabled', 'true');
            //END BUSTOUR

            //HOTELES
            let destination_hotel = $('#destination_hotel');
            let category_hotel = $('#category_hotel').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category')
            });
            let date_collect_hotel = $('#date_collect_hotel');
            let date_delivery_hotel = $('#date_delivery_hotel');
            let pack_hotel = $('#pack_hotel').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_pack')
            });
            let adults = $('#hotel_adultos');
            let childrens = $('#hotel_childrens');
            //END

            //SERVICIOS
            let destination_service = $('#destination_service');
            let category_service = $('#category_service').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category')
            });
            let amount_catering_services = $('#amount_catering_services').prop('disabled', true);

            let date_collect_service = $('#date_collect_service');
            let time_collect_service = $('#time_collect_service');

            //END SERVICES

            //BUS TRAVEL
            create_array_for_product('is_bus_owner', true);
            let out_bus = $('#out_bus');
            let destination_bus = $('#destination_bus').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_destiny')
            }).prop('disabled', true);
            let bus_location = $('#bus_location').prop('disabled', true).on('change', (e) => {
                location_value = e.target.value;
                make_comment();
            });
            let category_bus = $('#category_bus').select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category')
            }).prop('disabled', true);
            let date_collect_bus = $('#date_collect_bus').prop('disabled', true);
            let time_collect_bus = $('#time_collect_bus').on('change', function (e) {
                lang_value = e.target.value;
                make_comment();
            }).prop('disabled', true)
            let date_delivery_bus = $('#date_delivery_bus').prop('disabled', true);
            let time_delivery_bus = $('#time_delivery_bus').prop('disabled', true);
            let has_book_owner = $('#has_book_owner').prop('checked', true).trigger('change');
            //END BUS TRAVEL


            collect.prop("disabled", true);
            collect_place_car_lux.prop("disabled", true);
            delivery.prop("disabled", true);
            delivery_place_car_lux.prop("disabled", true);
            category.prop("disabled", true);
            category_car_lux.prop("disabled", true);
            category_bustour.prop("disabled", true);
            category_hotel.prop("disabled", true);
            pack_hotel.prop("disabled", true);
            category_service.prop("disabled", true);
            date_collect_hotel.prop("disabled", true);
            date_delivery_hotel.prop("disabled", true);
            date_collect_service.prop("disabled", true);
            time_collect_service.prop("disabled", true);
            modality_bikes.prop("disabled", true);


            //CHOFERES ADICIONALES
            if (create_array_for_product('drivers', '', 'show') === undefined) {
                create_array_for_product('drivers', 0);
            }

            has_drivers.on('change', function (e) {
                let driver_count = $('#drivers_count');
                if (e.target.checked) {
                    driver_count.removeClass('ocultar');
                    create_array_for_product('drivers', 1);
                    drivers.select2({
                        theme: "bootstrap", placeholder: I18n.t('global.main.form.select_drivers'), data: [{
                            id: 1, text: '1'
                        }, {
                            id: 2, text: '2'
                        }]
                    }).on('change', function (e) {
                        create_array_for_product('drivers', e.target.value);
                        setSessionVar('drivers', e.target.value, 'add', false).then();
                    })
                } else {
                    drivers.val(null).trigger('change');
                    driver_count.addClass('ocultar');
                    create_array_for_product('drivers', 0);
                    setSessionVar('drivers', '0', 'add', false).then();
                }
            })
            has_drivers_car_lux.on('change', function (e) {
                let driver_count = $('#drivers_count_car_lux');
                if (e.target.checked) {
                    driver_count.removeClass('ocultar');
                    create_array_for_product('drivers', 1);
                    drivers_car_lux.select2({
                        theme: "bootstrap", placeholder: I18n.t('global.main.form.select_drivers'), data: [{
                            id: 1, text: '1'
                        }, {
                            id: 2, text: '2'
                        }]
                    }).on('change', function (e) {
                        create_array_for_product('drivers', e.target.value);
                        setSessionVar('drivers', e.target.value, 'add', false).then();
                    })
                } else {
                    drivers_car_lux.val(null).trigger('change');
                    driver_count.addClass('ocultar');
                    create_array_for_product('drivers', 0);
                    setSessionVar('drivers', '0', 'add', false).then();
                }
            })
            has_drivers_bikes.on('change', function (e) {
                let drivers_count_bikes = $('#drivers_count_bikes');
                if (e.target.checked) {
                    drivers_count_bikes.removeClass('ocultar');
                    create_array_for_product('drivers', 1);
                    drivers_bikes.select2({
                        theme: "bootstrap", placeholder: I18n.t('global.main.form.select_drivers'), data: [{
                            id: 1, text: '1'
                        }, {
                            id: 2, text: '2'
                        }]
                    }).on('change', function (e) {
                        create_array_for_product('drivers', e.target.value);
                        setSessionVar('drivers', e.target.value, 'add', false).then();
                    })
                } else {
                    drivers_bikes.val(null).trigger('change');
                    drivers_count_bikes.addClass('ocultar');
                    create_array_for_product('drivers', 0);
                    setSessionVar('drivers', '0', 'add', false).then();
                }
            })
            //FIN DE CHOFERES ADICIONALES

            //SUPLEMENTO DE EDAD
            // setSessionVar('supplement_insurance','0','add',false).then();
            if (create_array_for_product('supplement_insurance', '', 'show') === undefined) {
                create_array_for_product('supplement_insurance', 0);
            }
            supplement_insurance.on('change', function (e) {
                if (e.target.checked) {
                    create_array_for_product('supplement_insurance', 1);
                    // setSessionVar('supplement_insurance','1','add',false).then();
                } else {
                    create_array_for_product('supplement_insurance', 0);
                    // setSessionVar('supplement_insurance','0','add',false).then();
                }
            })
            supplement_insurance_car_lux.on('change', function (e) {
                if (e.target.checked) {
                    create_array_for_product('supplement_insurance', 1);
                    // setSessionVar('supplement_insurance','1','add',false).then();
                } else {
                    create_array_for_product('supplement_insurance', 0);
                    // setSessionVar('supplement_insurance','0','add',false).then();
                }
            })
            supplement_insurance_bikes.on('change', function (e) {
                if (e.target.checked) {
                    create_array_for_product('supplement_insurance', 1);
                    // setSessionVar('supplement_insurance','1','add',false).then();
                } else {
                    create_array_for_product('supplement_insurance', 0);
                    // setSessionVar('supplement_insurance','0','add',false).then();
                }
            })
            //FIN DE SUPLEMENTO DE EDAD

            disable_dates().then(dd => {
                date_disabled = dd;
            })

            $.ajax({
                url: `${location.origin}/${locale.locale}/preferences/to/general?format=json`,
                data: {},
                type: "get",
                success: function (data) {
                    preferences = data;
                    data.map((elem, key) => {
                        if (data.hasOwnProperty(key)) {
                            let target = modifiedKey(data[key].key.text);
                            let show = data[key].show;
                            switch (target) {
                                case 'siteName':
                                    let logo = data[key].logo
                                    if (show) {
                                        if (context.hasSiteNameTarget) {
                                            // if (data[key].image_url !== undefined && data[key].image_url !== null && data[key].hasOwnProperty('image_url')) {
                                                if (location.pathname.includes("/admin") || location.pathname.includes("/initial/config")) {
                                                    context.siteNameTarget.innerHTML = `Administración <span>${data[key].value}</span>`;

                                                } else {
                                                    if (location.pathname.includes("/commercial")) {
                                                        context.siteNameTarget.innerHTML = `Comercial <span>${data[key].value}</span>`;
                                                    } else {
                                                        if(logo.includes('active_storage')){
                                                            context.siteNameTarget.innerHTML = `<i class='nav_logo-icon'><img alt="${data[key].value}" src="${data[key].logo}" style="width:30px;"> </i>
                                                                                                    <span class="nav_logo-name">${data[key].value}</span>`;
                                                        }else{
                                                            context.siteNameTarget.innerHTML = `<i class='nav_logo-icon'>${data[key].logo}</i>
                                                                                                    <span class="nav_logo-name">${data[key].value}</span>`;
                                                        }

                                                    }

                                                }
                                            // } else {
                                            //     context.siteNameTarget.innerHTML = `<span>${data[key].value}</span>`;
                                            // }

                                        }

                                        if (context.hasTicketLogoTarget) {
                                            if (data[key].image_url !== undefined || data[key].image_url !== null) {
                                                context.ticketLogoTarget.innerHTML = `<img src="${data[key].image_url}" alt="${data[key].value}">`;
                                            } else {
                                                context.ticketLogoTarget.innerHTML = `<span>${data[key].value}</span>`;
                                            }

                                        }

                                    } else {
                                        context.siteNameTarget.parentNode.remove();
                                    }

                                    break;
                                case 'collectPlace':
                                    if (context.hasCollectPlaceTarget) {
                                        context.collectPlaceTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'deliveryPlace':
                                    if (context.hasDeliveryPlaceTarget) {
                                        context.deliveryPlaceTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'textDestination':
                                    if (context.hasTextDestinationTarget) {
                                        context.textDestinationTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'dateCollect':
                                    if (context.hasDateCollectTarget) {
                                        context.dateCollectTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'dateDelivery':
                                    if (context.hasDateDeliveryTarget) {
                                        context.dateDeliveryTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'timeCollect':
                                    if (context.hasTimeCollectTarget) {
                                        context.timeCollectTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'timeDelivery':
                                    if (context.hasTimeDeliveryTarget) {
                                        context.timeDeliveryTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'textButton':
                                    if (context.hasTextButtonTarget) {
                                        context.textButtonTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'textsearchMark':
                                    if (context.hasTextSearchMarkTarget) {
                                        context.textSearchMarkTarget.innerHTML = data[key].value;
                                    }
                                    break;
                                case 'textsearchModel':
                                    if (context.hasTextSearchModelTarget) {
                                        context.textSearchModelTarget.innerHTML = data[key].value;
                                    }
                                    break;
                                case 'Category':
                                    if (context.hasCategoryTarget) {
                                        // context.categoryTarget.innerHTML = data[key].value;
                                        context.categoryTargets.map((elem) => {
                                            elem.innerText = data[key].value;
                                        })
                                    }
                                    break;
                                case 'contactNumber':
                                    if (context.hasContactNumberTarget) {
                                        context.contactNumberTarget.innerHTML = `${data[key].value}`;
                                    }
                                    if (context.hasContactNumberTwoTarget) {
                                        context.contactNumberTwoTarget.innerHTML = `${data[key].value}`;
                                    }
                                    break;
                                case 'siteNameFooter':
                                    if (context.hasSiteNameFooterTarget) {
                                        if (data[key].image_url !== undefined || data[key].image_url !== null) {
                                            context.siteNameFooterTarget.innerHTML = `<img src="${data[key].image_url}" alt="${data[key].value}">`;
                                        } else {
                                            context.siteNameFooterTarget.innerHTML = `<span>${data[key].value}</span>`;
                                        }
                                    }

                                    break;
                                case 'contactEmail':
                                    if (context.hasContactEmailTarget) {
                                        context.contactEmailTarget.innerHTML = `${data[key].value}`;
                                    }
                                    if (context.hasContactEmailTwoTarget) {
                                        context.contactEmailTwoTarget.innerHTML = `${data[key].value}`;
                                    }
                                    break;
                                case 'contactAddress':
                                    if (context.hasContactAddressTarget) {
                                        context.contactAddressTarget.innerHTML = `${data[key].value}`;
                                    }
                                    break;
                                case 'formText':
                                    if (context.hasFormTextTarget) {
                                        context.formTextTarget.innerHTML = `${data[key].value}`;
                                    }
                                    break;
                                case 'manyDrivers':
                                    if (context.hasManyDriversTarget) {
                                        context.manyDriversTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'supplementInsurance':
                                    if (context.hasSupplementInsuranceTarget) {
                                        context.supplementInsuranceTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'driversCount':
                                    if (context.hasDriversCountTarget) {
                                        context.driversCountTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                                case 'busResponsable':
                                    if (context.hasBusResponsibleTarget) {
                                        context.busResponsibleTargets.map((elem) => {
                                            elem.innerHTML = data[key].value;
                                        })
                                    }
                                    break;
                            }
                        }
                    })
                },
                complete: function () {
                    //FORMULARIO PARA AUTOS
                    destination.select2({
                        theme: "bootstrap", placeholder: I18n.t('global.main.form.select_destiny'), ajax: {
                            url: `${location.origin}/${locale.locale}/destination`,
                            dataType: "json",
                            language: "es",
                            data: function (params) {
                                return {
                                    form_type: form_type_send[0].dataset.type,
                                    search: params.term,
                                    page: params.page || 1
                                }

                            },
                            type: 'GET',
                            processResults: function (data, params) {
                                let result = [];
                                params.page = params.page || 1;

                                data.rows.map((elem, key) => {
                                    result.push({
                                        id: elem.code + '|' + elem.id, text: elem.name
                                    })
                                })

                                if (data.total > 10) {

                                    if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                                        return {
                                            results: result, "pagination": {
                                                "more": true
                                            }
                                        }
                                    } else {
                                        return {
                                            results: result, "pagination": {
                                                "more": false
                                            }
                                        }
                                    }

                                } else {
                                    return {
                                        results: result, "pagination": {
                                            "more": false
                                        }
                                    }
                                }
                            }
                        }
                    }).on('change', function (e) {
                        //LIMPIAR SELECT2
                        collect.empty();
                        delivery.empty();
                        category.empty();
                        //FIN DE LIMPIEZA

                        let split = e.target.value.split('|');
                        destino = split[0]
                        let destino_id = split[1]
                        create_array_for_product('product_type', form_type_send[0].dataset.type);
                        create_array_for_product('destination', destino);
                        create_array_for_product('amount', 1);
                        create_array_for_product('pax', 1);
                        create_array_for_product('destination_id', e.target.value.split("|")[1]);
                        create_array_for_product('text_destination', $(e.target).select2('data')[0].text);


                        delivery.select2({
                            theme: "bootstrap",
                            language: "es",
                            placeholder: I18n.t('global.main.form.select_office'),
                            ajax: {
                                url: `${location.origin}/${locale.locale}/office_by_destination`,
                                data: function (params) {
                                    return {
                                        search: params.term, page: params.page || 1,
                                        type: form_type_send[0].dataset.type
                                    }

                                },
                                type: "GET",
                                processResults: function (data, params) {
                                    let result = [];
                                    data.offices.map((elem, key) => {
                                        let aux = {
                                            text: elem.dealer_name, children: []
                                        };


                                        if (elem.offices.length > 0) {
                                            elem.offices.map((child, number) => {
                                                if (child.active) {
                                                    aux.children.push({
                                                        id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                                        text: child.name
                                                    });
                                                }

                                            })

                                            result.push(aux);
                                        }
                                    })

                                    if (result.length === 0) {
                                        result.push({
                                            text: "No hay datos para mostrar", children: []
                                        });
                                    }

                                    return {
                                        results: result
                                    }
                                }
                            }
                        }).prop("disabled", false).on('change', function (e) {
                            entrega = e.target.value;
                            entrega = entrega.split('-');

                            create_array_for_product('drop_off_place', entrega[0]);
                            create_array_for_product('delivery_place_text', $(e.target).select2('data')[0].text);


                            let helper_delivery = $(delivery[0].parentNode).find('.helper-text');
                            let tootltip_button_delivery = $(delivery[0].parentNode).find('.tooltip-button');
                            let text_helper = '';
                            let full_time = entrega[3].split('full_time:')[1];
                            if (full_time === "false") {
                                let from = entrega[4].split('open_time:')[1] + ":00am";
                                let to = (parseInt(entrega[5].split("close_time:")[1]) - 12) + ":00pm";
                                text_helper = I18n.t('global.main.form.work_by_hours', {from: from, to: to});
                            } else {
                                text_helper = I18n.t('global.main.form.work_all_day');

                            }
                            helper_delivery.text(text_helper);
                            tootltip_button_delivery[0].classList.remove('hide-tooltip');
                        })
                        collect.select2({
                            theme: "bootstrap",
                            language: "es",
                            placeholder: I18n.t('global.main.form.select_office'),
                            ajax: {
                                url: `${location.origin}/${locale.locale}/office_by_destination`,
                                data: function (params) {
                                    return {
                                        search: params.term, page: params.page || 1, destination: destino_id,
                                        type: form_type_send[0].dataset.type
                                    }

                                },
                                type: "GET",
                                processResults: function (data, params) {
                                    let result = [];
                                    data.offices.map((elem, key) => {
                                        let aux = {
                                            text: elem.dealer_name, children: []
                                        };


                                        if (elem.offices.length > 0) {
                                            elem.offices.map((child, number) => {
                                                if (child.active) {
                                                    aux.children.push({
                                                        id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                                        text: child.name
                                                    });
                                                }

                                            })

                                            result.push(aux);
                                        }
                                    })

                                    if (result.length === 0) {
                                        result.push({
                                            text: "No hay datos para mostrar", children: []
                                        });
                                    }

                                    return {
                                        results: result
                                    }
                                }
                            }
                        }).prop("disabled", false).on('change', function (e) {
                            recogida = e.target.value;
                            recogida = recogida.split('-');

                            create_array_for_product('pick_up_place', recogida[0]);
                            create_array_for_product('dealer', recogida[1].split('dealer:')[1]);
                            create_array_for_product('dealer_name', recogida[2].split('dealer_name:')[1]);
                            create_array_for_product('collect_place_text', $(e.target).select2('data')[0].text);

                            let helper = $(collect[0].parentNode).find('.helper-text');
                            let tootltip_button = $(collect[0].parentNode).find('.tooltip-button');
                            let text_helper = '';

                            //ESTO ES TEMPORAL
                            category.select2({
                                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category'), ajax: {
                                    url: `${location.origin}/${locale.locale}/service_by_dealer`,
                                    data: function (params) {
                                        return {
                                            form_type: form_type_send[0].dataset.type,
                                            search: params.term,
                                            page: params.page || 1,
                                            dealer: recogida[1].split('dealer:')[1]
                                        }

                                    },
                                    type: "GET",
                                    processResults: function (data, params) {
                                        let result = [];
                                        categories = data;
                                        if (data.services.length > 0) {
                                            let aux = {
                                                text: data.dealer_name, children: []
                                            };

                                            data.services.map((child, number) => {
                                                if (child !== null) {
                                                    if (child.booking_type === 'car') {
                                                        aux.children.push({
                                                            id: child.code, text: child.name
                                                        });
                                                    }
                                                }
                                            })
                                            result.push(aux);


                                            return {
                                                results: result
                                            }
                                        } else {
                                            return {
                                                results: [{
                                                    text: "No hay datos para mostrar", children: []
                                                }]
                                            }
                                        }

                                    }
                                }
                            }).prop("disabled", false).on('change', function (e) {
                                create_array_for_product('category', e.target.value);

                                get_modalities(null, recogida[1].split('dealer:')[1], 'code', e.target.value, form_type_send[0].dataset.type).then(gmodality => {

                                })

                                create_array_for_product('text_category', $(e.target).select2('data')[0].text);

                                categories_details(e.target.value).then(category_details => {
                                    let date_collect = $('#date_collect');
                                    let time_delivery = $('#time_delivery');
                                    let time_collect = $('#time_collect');
                                    setSessionVar('day_delay_rent', category_details.reservation_time, 'add', false);
                                    setSessionVar('day_min_rent', category_details.min_length_reservation, 'add', false);
                                    setSessionVar('day_max_rent', category_details.max_length_reservation, 'add', false);
                                    // setSessionVar('category_description', category_details.description, 'add', false);
                                    setSessionVar('use_same_hours', category_details.same_hours, 'add', false);

                                    if (!date_collect[0].value.isEmpty() && category_details.same_hours === false) {
                                        time_delivery.prop('disabled', false);
                                        time_delivery[0].value = '';
                                        // setSessionVar('time_delivery', '', 'delete', false).then();
                                        create_array_for_product('time_delivery', '', 'delete')
                                    } else {
                                        if (!date_collect[0].value.isEmpty() && category_details.same_hours === true) {
                                            time_delivery.prop('disabled', true);
                                            time_delivery[0].value = time_collect[0].value;
                                            // setSessionVar('time_delivery', time_collect[0].value, 'add', false).then()
                                            create_array_for_product('time_delivery', time_collect[0].value);
                                        }
                                    }

                                    date_collect.prop('disabled', false);
                                    time_collect.prop('disabled', false);


                                    flatpickr("#date_collect", {
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        disable: date_disabled,
                                        disableMobile: "true",
                                        minDate: new Date().fp_incr(parseInt(category_details.reservation_time)),
                                        maxDate: new Date().fp_incr(180),
                                        locale: flatpick_lang
                                    });
                                })


                            })
                            //    HASTA AQUI ES EL TEMPORAL
                            create_array_for_product('full_time', recogida[3].split('full_time:')[1]);

                            let full_time = recogida[3].split('full_time:')[1];
                            if (full_time === "false") {
                                create_array_for_product('open_time', recogida[4].split('open_time:')[1] + ":00");
                                create_array_for_product('close_time', recogida[5].split('close_time:')[1] + ":00");
                                let from = recogida[4].split('open_time:')[1] + ":00am";
                                let to = (parseInt(recogida[5].split("close_time:")[1]) - 12) + ":00pm";
                                text_helper = I18n.t('global.main.form.work_by_hours', {from: from, to: to});
                            } else {
                                text_helper = I18n.t('global.main.form.work_all_day');
                            }
                            helper.text(text_helper);
                            tootltip_button[0].classList.remove('hide-tooltip');


                        });
                    });
                }
            });

            $('#date_collect').on('change', (e) => {
                //ESTABLECER HORAS SELECCIONABLES EN DEPENDENCIA DEL DIA QUE SE SELECCIONE
                let min_time = create_array_for_product('open_time', '', 'show');
                let max_time = create_array_for_product('close_time', '', 'show');
                let selected_date = e.target.value.split('-');
                let especial_behavior = false;
                if (`${year}-${month}-${day}` === e.target.value || (year === parseInt(selected_date[0]) && parseInt(month) === parseInt(selected_date[1]) && (parseInt(selected_date[2]) - day) === 1)) {
                    especial_behavior = true;
                    min_time = `${hour}:${minutes}`;
                    max_time = "23:55";
                }

                if (create_array_for_product('full_time', '', 'show') === "false") {
                    flatpickr(".timepicker", {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        defaultDate: `${hour}:${minutes}`,
                        altFormat: "H:i",
                        time_24hr: true,
                        locale: flatpick_lang,
                        disableMobile: "true",
                        minTime: min_time,
                        maxTime: max_time,
                    })

                } else {
                    if(especial_behavior){
                        flatpickr(".timepicker", {
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            defaultDate: min_time,
                            altFormat: "H:i",
                            time_24hr: true,
                            locale: flatpick_lang,
                            disableMobile: "true",
                            minTime: min_time,
                            maxTime: max_time,
                        })
                    }else{
                        flatpickr(".timepicker", {
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            defaultDate: min_time,
                            altFormat: "H:i",
                            time_24hr: true,
                            locale: flatpick_lang,
                            disableMobile: "true",

                        })
                    }

                }

                //FIN DE HORAS

                //VERIFICAR TEMPORADA
                verify_season(e.target.value).then(vs => {


                    let max_date = 0;
                    let min_date = 0;

                    if (vs.length > 0) {
                        max_date = parseInt(vs[0].max) + 1;
                        min_date = parseInt(vs[0].min) + 1;
                    } else {
                        max_date = parseInt(sessionStorage.getItem('day_max_rent')) + 1;
                        min_date = parseInt(sessionStorage.getItem('day_min_rent')) + 1;
                    }
                    $('#date_delivery').prop('disabled', false);
                    flatpickr("#date_delivery", {
                        altInput: true,
                        altFormat: "d-m-Y",
                        dateFormat: "Y-m-d",
                        disableMobile: "true",
                        disable: date_disabled,
                        minDate: new Date(e.target.value).fp_incr(min_date),
                        maxDate: new Date(e.target.value).fp_incr(max_date),
                        locale: flatpick_lang
                    });

                    if (sessionStorage.getItem('use_same_hours') !== 'true') {
                        $('#time_delivery').prop('disabled', false);
                    }

                });
            })
            $('#time_collect').on('change', (e) => {
                if (sessionStorage.getItem('use_same_hours') === 'true') {
                    let time_delivery = $('#time_delivery');
                    time_delivery[0].value = e.target.value;
                    // setSessionVar('time_delivery', e.target.value, 'add', false).then()
                    create_array_for_product('time_delivery', e.target.value);
                }
            })

            //FORMULARIO PARA AUTOS DE LUJO
            destination_car_lux.select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_destiny'), ajax: {
                    url: `${location.origin}/${locale.locale}/destination`,
                    dataType: "json",
                    language: "es",
                    data: function (params) {
                        return {
                            form_type: form_type_send[0].dataset.type, search: params.term, page: params.page || 1
                        }

                    },
                    type: 'GET',
                    processResults: function (data, params) {
                        let result = [];
                        params.page = params.page || 1;

                        data.rows.map((elem, key) => {
                            result.push({
                                id: elem.code + '|' + elem.id, text: elem.name
                            })
                        })

                        if (data.total > 10) {

                            if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                                return {
                                    results: result, "pagination": {
                                        "more": true
                                    }
                                }
                            } else {
                                return {
                                    results: result, "pagination": {
                                        "more": false
                                    }
                                }
                            }

                        } else {
                            return {
                                results: result, "pagination": {
                                    "more": false
                                }
                            }
                        }
                    }
                }
            }).on('change', function (e) {
                //LIMPIAR SELECT2
                collect_place_car_lux.empty();
                delivery_place_car_lux.empty();
                category_car_lux.empty();
                //FIN DE LIMPIEZA

                let split = e.target.value.split('|');
                destino = split[0]
                let destino_id = split[1]
                create_array_for_product('product_type', form_type_send[0].dataset.type);
                create_array_for_product('destination', destino);
                create_array_for_product('destination_id', e.target.value.split("|")[1]);
                create_array_for_product('text_destination', $(e.target).select2('data')[0].text);


                delivery_place_car_lux.select2({
                    theme: "bootstrap", language: "es", placeholder: I18n.t('global.main.form.select_office'), ajax: {
                        url: `${location.origin}/${locale.locale}/office_by_destination`, data: function (params) {
                            return {
                                search: params.term, page: params.page || 1, type: form_type_send[0].dataset.type
                            }

                        }, type: "GET", processResults: function (data, params) {
                            let result = [];
                            data.offices.map((elem, key) => {
                                let aux = {
                                    text: elem.dealer_name, children: []
                                };


                                if (elem.offices.length > 0) {
                                    elem.offices.map((child, number) => {
                                        if (child.active) {
                                            aux.children.push({
                                                id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                                text: child.name
                                            });
                                        }

                                    })

                                    result.push(aux);
                                }
                            })

                            if (result.length === 0) {
                                result.push({
                                    text: "No hay datos para mostrar", children: []
                                });
                            }

                            return {
                                results: result
                            }
                        }
                    }
                }).prop("disabled", false).on('change', function (e) {
                    entrega = e.target.value;
                    entrega = entrega.split('-');

                    create_array_for_product('drop_off_place', entrega[0]);
                    create_array_for_product('delivery_place_text', $(e.target).select2('data')[0].text);


                    let helper_delivery = $(delivery_place_car_lux[0].parentNode).find('.helper-text');
                    let tootltip_button_delivery = $(delivery_place_car_lux[0].parentNode).find('.tooltip-button');
                    let text_helper = '';
                    let full_time = entrega[3].split('full_time:')[1];
                    if (full_time === "false") {
                        let from = entrega[4].split('open_time:')[1] + ":00am";
                        let to = (parseInt(entrega[5].split("close_time:")[1]) - 12) + ":00pm";
                        text_helper = I18n.t('global.main.form.work_by_hours', {from: from, to: to});
                    } else {
                        text_helper = I18n.t('global.main.form.work_all_day');

                    }
                    helper_delivery.text(text_helper);
                    tootltip_button_delivery[0].classList.remove('hide-tooltip');
                })
                collect_place_car_lux.select2({
                    theme: "bootstrap", language: "es", placeholder: I18n.t('global.main.form.select_office'), ajax: {
                        url: `${location.origin}/${locale.locale}/office_by_destination`, data: function (params) {
                            return {
                                search: params.term,
                                page: params.page || 1,
                                destination: destino_id,
                                type: form_type_send[0].dataset.type
                            }

                        }, type: "GET", processResults: function (data, params) {
                            let result = [];
                            data.offices.map((elem, key) => {
                                let aux = {
                                    text: elem.dealer_name, children: []
                                };


                                if (elem.offices.length > 0) {
                                    elem.offices.map((child, number) => {
                                        if (child.active) {
                                            aux.children.push({
                                                id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                                text: child.name
                                            });
                                        }

                                    })

                                    result.push(aux);
                                }
                            })

                            if (result.length === 0) {
                                result.push({
                                    text: "No hay datos para mostrar", children: []
                                });
                            }

                            return {
                                results: result
                            }
                        }
                    }
                }).prop("disabled", false).on('change', function (e) {
                    recogida = e.target.value;
                    recogida = recogida.split('-');

                    create_array_for_product('pick_up_place', recogida[0]);
                    create_array_for_product('dealer', recogida[1].split('dealer:')[1]);
                    create_array_for_product('dealer_name', recogida[2].split('dealer_name:')[1]);
                    create_array_for_product('collect_place_text', $(e.target).select2('data')[0].text);

                    let helper = $(collect_place_car_lux[0].parentNode).find('.helper-text');
                    let tootltip_button = $(collect_place_car_lux[0].parentNode).find('.tooltip-button');
                    let text_helper = '';

                    //ESTO ES TEMPORAL
                    category_car_lux.select2({
                        theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category'), ajax: {
                            url: `${location.origin}/${locale.locale}/service_by_dealer`, data: function (params) {
                                return {
                                    form_type: form_type_send[0].dataset.type,
                                    search: params.term,
                                    page: params.page || 1,
                                    dealer: recogida[1].split('dealer:')[1]
                                }

                            }, type: "GET", processResults: function (data, params) {
                                let result = [];
                                categories = data;
                                if (data.services.length > 0) {
                                    let aux = {
                                        text: data.dealer_name, children: []
                                    };

                                    data.services.map((child, number) => {
                                        if (child !== null) {
                                            if (child.booking_type === 'car') {
                                                aux.children.push({
                                                    id: child.code, text: child.name
                                                });
                                            }
                                        }
                                    })
                                    result.push(aux);


                                    return {
                                        results: result
                                    }
                                } else {
                                    return {
                                        results: [{
                                            text: "No hay datos para mostrar", children: []
                                        }]
                                    }
                                }

                            }
                        }
                    }).prop("disabled", false).on('change', function (e) {
                        create_array_for_product('category', e.target.value);
                        get_modalities(null, recogida[1].split('dealer:')[1], 'code', e.target.value, form_type_send[0].dataset.type).then(gmodality => {

                        })

                        create_array_for_product('text_category', $(e.target).select2('data')[0].text);

                        categories_details(e.target.value).then(category_details => {
                            let date_collect = $('#date_collect_car_lux');
                            let time_delivery = $('#time_delivery_car_lux');
                            let time_collect = $('#time_collect_car_lux');
                            setSessionVar('day_delay_rent', category_details.reservation_time, 'add', false);
                            setSessionVar('day_min_rent', category_details.min_length_reservation, 'add', false);
                            setSessionVar('day_max_rent', category_details.max_length_reservation, 'add', false);
                            // setSessionVar('category_description', category_details.description, 'add', false);
                            setSessionVar('use_same_hours', category_details.same_hours, 'add', false);

                            if (!date_collect[0].value.isEmpty() && category_details.same_hours === false) {
                                time_delivery.prop('disabled', false);
                                time_delivery[0].value = '';
                                // setSessionVar('time_delivery', '', 'delete', false).then();
                                create_array_for_product('time_delivery', '', 'delete')
                            } else {
                                if (!date_collect[0].value.isEmpty() && category_details.same_hours === true) {
                                    time_delivery.prop('disabled', true);
                                    time_delivery[0].value = time_collect[0].value;
                                    // setSessionVar('time_delivery', time_collect[0].value, 'add', false).then()
                                    create_array_for_product('time_delivery', time_collect[0].value);
                                }
                            }

                            date_collect.prop('disabled', false);
                            time_collect.prop('disabled', false);

                            flatpickr("#date_collect_car_lux", {
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                disable: date_disabled,
                                disableMobile: "true",
                                minDate: new Date().fp_incr(parseInt(category_details.reservation_time)),
                                maxDate: new Date().fp_incr(180),
                                locale: flatpick_lang
                            });
                        })


                    })
                    //    HASTA AQUI ES EL TEMPORAL

                    create_array_for_product('full_time', recogida[3].split('full_time:')[1]);

                    let full_time = recogida[3].split('full_time:')[1];
                    if (full_time === "false") {
                        create_array_for_product('open_time', recogida[4].split('open_time:')[1] + ":00");
                        create_array_for_product('close_time', recogida[5].split('close_time:')[1] + ":00");
                        let from = recogida[4].split('open_time:')[1] + ":00am";
                        let to = (parseInt(recogida[5].split("close_time:")[1]) - 12) + ":00pm";
                        text_helper = I18n.t('global.main.form.work_by_hours', {from: from, to: to});
                    } else {
                        text_helper = I18n.t('global.main.form.work_all_day');
                    }

                    helper.text(text_helper);
                    tootltip_button[0].classList.remove('hide-tooltip');
                });
            });
            $('#date_collect_car_lux').on('change', (e) => {

                //ESTABLECER HORAS SELECCIONABLES EN DEPENDENCIA DEL DIA QUE SE SELECCIONE
                let min_time = create_array_for_product('open_time', '', 'show');
                let max_time = create_array_for_product('close_time', '', 'show');
                let selected_date = e.target.value.split('-');
                let especial_behavior = false;
                if (`${year}-${month}-${day}` === e.target.value || (year === parseInt(selected_date[0]) && parseInt(month) === parseInt(selected_date[1]) && (parseInt(selected_date[2]) - day) === 1)) {
                    especial_behavior = true;
                    min_time = `${hour}:${minutes}`;
                    max_time = "23:55";
                }

                if (create_array_for_product('full_time', '', 'show') === "false") {
                    flatpickr(".timepicker", {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        defaultDate: `${hour}:${minutes}`,
                        altFormat: "H:i",
                        time_24hr: true,
                        locale: flatpick_lang,
                        disableMobile: "true",
                        minTime: min_time,
                        maxTime: max_time,
                    })

                } else {
                    if(especial_behavior){
                        flatpickr(".timepicker", {
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            defaultDate: min_time,
                            altFormat: "H:i",
                            time_24hr: true,
                            locale: flatpick_lang,
                            disableMobile: "true",
                            minTime: min_time,
                            maxTime: max_time,
                        })
                    }else{
                        flatpickr(".timepicker", {
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            defaultDate: min_time,
                            altFormat: "H:i",
                            time_24hr: true,
                            locale: flatpick_lang,
                            disableMobile: "true",

                        })
                    }
                }

                //FIN DE HORAS

                //VERIFICAR TEMPORADA
                verify_season(e.target.value).then(vs => {
                    let max_date = 0;
                    let min_date = 0;

                    if (vs.length > 0) {
                        max_date = parseInt(vs[0].max) + 1;
                        min_date = parseInt(vs[0].min) + 1;
                    } else {
                        max_date = parseInt(sessionStorage.getItem('day_max_rent')) + 1;
                        min_date = parseInt(sessionStorage.getItem('day_min_rent')) + 1;
                    }
                    $('#date_delivery').prop('disabled', false);
                    flatpickr("#date_delivery_car_lux", {
                        altInput: true,
                        altFormat: "d-m-Y",
                        dateFormat: "Y-m-d",
                        disableMobile: "true",
                        disable: date_disabled,
                        minDate: new Date(e.target.value).fp_incr(min_date),
                        maxDate: new Date(e.target.value).fp_incr(max_date),
                        locale: flatpick_lang
                    });

                    if (sessionStorage.getItem('use_same_hours') !== 'true') {
                        $('#time_delivery').prop('disabled', false);
                    }

                });
            })

            $('#time_collect_car_lux').on('change', (e) => {
                if (sessionStorage.getItem('use_same_hours') === 'true') {
                    let time_delivery = $('#time_delivery_car_lux');
                    time_delivery[0].value = e.target.value;
                    // setSessionVar('time_delivery', e.target.value, 'add', false).then()
                    create_array_for_product('time_delivery', e.target.value);
                }
            })
            //FIN DE FORMULARIO PARA AUTOS DE LUJO

            //FORMULARIO PARA MOTOS
            destination_bikes.select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_destiny'), ajax: {
                    url: `${location.origin}/${locale.locale}/destination`,
                    dataType: "json",
                    language: "es",
                    data: function (params) {
                        return {
                            form_type: form_type_send[0].dataset.type, search: params.term, page: params.page || 1
                        }

                    },
                    type: 'GET',
                    processResults: function (data, params) {
                        let result = [];
                        params.page = params.page || 1;

                        data.rows.map((elem, key) => {
                            result.push({
                                id: elem.code + '|' + elem.id, text: elem.name
                            })
                        })

                        if (data.total > 10) {

                            if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                                return {
                                    results: result, "pagination": {
                                        "more": true
                                    }
                                }
                            } else {
                                return {
                                    results: result, "pagination": {
                                        "more": false
                                    }
                                }
                            }

                        } else {
                            return {
                                results: result, "pagination": {
                                    "more": false
                                }
                            }
                        }
                    }
                }
            }).on('change', function (e) {
                //LIMPIAR SELECT2
                collect_place_bikes.empty();
                category_bikes.empty();
                //FIN DE LIMPIEZA

                let split = e.target.value.split('|');
                destino = split[0]
                let destino_id = split[1]
                create_array_for_product('product_type', form_type_send[0].dataset.type);
                create_array_for_product('destination', destino);
                create_array_for_product('destination_id', e.target.value.split("|")[1]);
                create_array_for_product('text_destination', $(e.target).select2('data')[0].text);
                create_array_for_product('amount', 1);
                create_array_for_product('pax', 1);

                collect_place_bikes.select2({
                    theme: "bootstrap", language: "es", placeholder: I18n.t('global.main.form.select_office'), ajax: {
                        url: `${location.origin}/${locale.locale}/office_by_destination`, data: function (params) {
                            return {
                                search: params.term, page: params.page || 1, destination: destino_id,
                                type: form_type_send[0].dataset.type
                            }

                        }, type: "GET", processResults: function (data, params) {
                            let result = [];
                            data.offices.map((elem, key) => {
                                let aux = {
                                    text: elem.dealer_name, children: []
                                };


                                if (elem.offices.length > 0) {
                                    elem.offices.map((child, number) => {
                                        if (child.active) {
                                            aux.children.push({
                                                id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                                text: child.name
                                            });
                                        }

                                    })

                                    result.push(aux);
                                }
                            })

                            if (result.length === 0) {
                                result.push({
                                    text: "No hay datos para mostrar", children: []
                                });
                            }

                            return {
                                results: result
                            }
                        }
                    }
                }).prop("disabled", false).on('change', function (e) {
                    recogida = e.target.value;
                    recogida = recogida.split('-');

                    create_array_for_product('pick_up_place', recogida[0]);
                    create_array_for_product('dealer', recogida[1].split('dealer:')[1]);
                    create_array_for_product('dealer_name', recogida[2].split('dealer_name:')[1]);
                    create_array_for_product('collect_place_text', $(e.target).select2('data')[0].text);
                    create_array_for_product('drop_off_place', recogida[0]);
                    create_array_for_product('delivery_place_text', $(e.target).select2('data')[0].text);

                    let helper = $(collect_place_bikes[0].parentNode).find('.helper-text');
                    let tootltip_button = $(collect_place_bikes[0].parentNode).find('.tooltip-button');
                    let text_helper = '';

                    //ESTO ES TEMPORAL
                    category_bikes.select2({
                        theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category'), ajax: {
                            url: `${location.origin}/${locale.locale}/service_by_dealer`, data: function (params) {
                                return {
                                    form_type: form_type_send[0].dataset.type,
                                    search: params.term,
                                    page: params.page || 1,
                                    dealer: recogida[1].split('dealer:')[1]
                                }

                            }, type: "GET", processResults: function (data, params) {
                                let result = [];
                                categories = data;
                                if (data.services.length > 0) {
                                    let aux = {
                                        text: data.dealer_name, children: []
                                    };

                                    data.services.map((child, number) => {
                                        if (child !== null) {
                                            if (child.booking_type === 'car') {
                                                aux.children.push({
                                                    id: child.code, text: child.name
                                                });
                                            }
                                        }
                                    })
                                    result.push(aux);


                                    return {
                                        results: result
                                    }
                                } else {
                                    return {
                                        results: [{
                                            text: "No hay datos para mostrar", children: []
                                        }]
                                    }
                                }

                            }
                        }
                    }).prop("disabled", false).on('change', function (e) {
                        let product_category = e.target.value;
                        create_array_for_product('category', product_category);
                        get_modalities(null, recogida[1].split('dealer:')[1], 'code', e.target.value, form_type_send[0].dataset.type).then(gmodality => {
                        })
                        create_array_for_product('text_category', $(e.target).select2('data')[0].text);

                        categories_details(e.target.value).then(category_details => {
                            let date_collect_bikes = $('#date_collect_bikes');
                            let time_collect_bikes = $('#time_collect_bikes');
                            let time_delivery_bikes = $('#time_delivery_bikes');
                            setSessionVar('day_delay_rent', category_details.reservation_time, 'add', false);
                            setSessionVar('day_min_rent', category_details.min_length_reservation, 'add', false);
                            setSessionVar('day_max_rent', category_details.max_length_reservation, 'add', false);
                            // setSessionVar('category_description', category_details.description, 'add', false);
                            setSessionVar('use_same_hours', 'true', 'add', false);

                            time_delivery_bikes.prop('disabled', true);
                            time_delivery_bikes[0].value = time_collect_bikes[0].value;

                            create_array_for_product('time_delivery', time_collect_bikes[0].value);


                            date_collect_bikes.prop('disabled', false);
                            time_collect_bikes.prop('disabled', false);


                            flatpickr("#date_collect_bikes", {
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                disable: date_disabled,
                                disableMobile: "true",
                                minDate: new Date().fp_incr(parseInt(category_details.reservation_time)),
                                maxDate: new Date().fp_incr(180),
                                locale: flatpick_lang
                            });

                        })

                        $.ajax({
                            url: `${location.origin}/${sessionStorage.getItem('locale')}/load_modalities`, data: {
                                form_type: form_type_send[0].dataset.type,
                                dealer: recogida[1].split('dealer:')[1],
                                product: product_category,
                                modality_name: 'PUBLICA'
                            }, type: 'GET', success: function (data) {
                                if (data.success) {
                                    let text_modality_bikes = $('#text_modality_bikes');
                                    let helper = $(text_modality_bikes[0].parentElement).find('.helper-text');

                                    if (parseInt(data.count) > 1) {
                                        let data_result = [{
                                            id: 0, text: I18n.t('global.main.form.select_modality')
                                        }];
                                        text_modality_bikes[0].classList.remove('ocultar');
                                        helper[0].innerHTML = "";

                                        data.data.map((index, elem) => {

                                            let text_modality_show = index.name.split(" ");
                                            text_modality_show.shift();
                                            text_modality_show.join("");

                                            data_result.push({
                                                id: index.code + "|" + index.name + "|" + index.id,
                                                text: text_modality_show
                                            })
                                        })

                                        modality_bikes.select2({
                                            theme: "bootstrap",
                                            placeholder: I18n.t('global.main.form.select_modality'),
                                            data: data_result
                                        }).prop("disabled", false).on('change', function (e) {
                                            let modality_selected = e.target.value.split("|");
                                            create_array_for_product('modality_code', modality_selected[0])
                                            create_array_for_product('modalities_name', modality_selected[1])
                                            create_array_for_product('has_modality', true);
                                        })
                                    } else {
                                        text_modality_bikes[0].classList.add('ocultar');
                                        helper[0].innerHTML = "<br> <p class='small'>No es necesario seleccionar una modalidad.</p>"
                                        create_array_for_product('modality_code', data.data[0].code)
                                        create_array_for_product('modalities_name', data.data[0].name)
                                    }

                                } else {
                                    toastr.warning(data.msg, 'Warning');
                                }
                            }
                        })


                    })

                    //    HASTA AQUI ES EL TEMPORAL

                    create_array_for_product('full_time', recogida[3].split('full_time:')[1]);

                    let full_time = recogida[3].split('full_time:')[1];
                    if (full_time === "false") {
                        create_array_for_product('open_time', recogida[4].split('open_time:')[1] + ":00");
                        create_array_for_product('close_time', recogida[5].split('close_time:')[1] + ":00");
                        let from = recogida[4].split('open_time:')[1] + ":00am";
                        let to = (parseInt(recogida[5].split("close_time:")[1]) - 12) + ":00pm";
                        text_helper = I18n.t('global.main.form.work_by_hours', {from: from, to: to});
                    } else {

                        text_helper = I18n.t('global.main.form.work_all_day');

                    }
                    helper.text(text_helper);
                    tootltip_button[0].classList.remove('hide-tooltip');


                });
            });

            $('#date_collect_bikes').on('change', (e) => {
//ESTABLECER HORAS SELECCIONABLES EN DEPENDENCIA DEL DIA QUE SE SELECCIONE
                let min_time = create_array_for_product('open_time', '', 'show');
                let max_time = create_array_for_product('close_time', '', 'show');
                let selected_date = e.target.value.split('-');
                let especial_behavior = false;
                if (`${year}-${month}-${day}` === e.target.value || (year === parseInt(selected_date[0]) && parseInt(month) === parseInt(selected_date[1]) && (parseInt(selected_date[2]) - day) === 1)) {
                    especial_behavior = true;
                    min_time = `${hour}:${minutes}`;
                    max_time = "23:55";
                }

                if (create_array_for_product('full_time', '', 'show') === "false") {
                    flatpickr(".timepicker", {
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i",
                        defaultDate: `${hour}:${minutes}`,
                        altFormat: "H:i",
                        time_24hr: true,
                        locale: flatpick_lang,
                        disableMobile: "true",
                        minTime: min_time,
                        maxTime: max_time,
                    })

                } else {
                    if(especial_behavior){
                        flatpickr(".timepicker", {
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            defaultDate: min_time,
                            altFormat: "H:i",
                            time_24hr: true,
                            locale: flatpick_lang,
                            disableMobile: "true",
                            minTime: min_time,
                            maxTime: max_time,
                        })
                    }else{
                        flatpickr(".timepicker", {
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "H:i",
                            defaultDate: min_time,
                            altFormat: "H:i",
                            time_24hr: true,
                            locale: flatpick_lang,
                            disableMobile: "true",

                        })
                    }
                }

                //FIN DE HORAS

                //VERIFICAR TEMPORADA
                verify_season(e.target.value).then(vs => {


                    let max_date = 0;
                    let min_date = 0;

                    if (vs.length > 0) {
                        max_date = parseInt(vs[0].max) + 1;
                        min_date = parseInt(vs[0].min) + 1;
                    } else {
                        max_date = parseInt(sessionStorage.getItem('day_max_rent')) + 1;
                        min_date = parseInt(sessionStorage.getItem('day_min_rent')) + 1;
                    }
                    $('#date_delivery_bikes').prop('disabled', false);

                    flatpickr("#date_delivery_bikes", {
                        altInput: true,
                        altFormat: "d-m-Y",
                        dateFormat: "Y-m-d",
                        disableMobile: "true",
                        disable: date_disabled,
                        minDate: new Date(e.target.value).fp_incr(min_date),
                        maxDate: new Date(e.target.value).fp_incr(max_date),
                        locale: flatpick_lang
                    });


                });
            })
            $('#time_collect_bikes').on('change', (e) => {
                let time_delivery = $('#time_delivery_bikes');
                time_delivery[0].value = e.target.value;
                create_array_for_product('time_delivery', e.target.value);
            })
            //FIN DE FORMULARIO PARA MOTOS

            //FORMULARIO PARA HOTELES
            destination_hotel.select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_destiny'), ajax: {
                    url: `${location.origin}/${locale.locale}/destination`,
                    dataType: "json",
                    language: "es",
                    data: function (params) {
                        return {
                            form_type: form_type_send[0].dataset.type,
                            search: params.term,
                            page: params.page || 1
                        }

                    },
                    type: 'GET',
                    processResults: function (data, params) {
                        let result = [];
                        params.page = params.page || 1;
                        data.rows.map((elem, key) => {
                            result.push({
                                id: elem.code + '|' + elem.id, text: elem.name
                            })
                        })
                        if (data.total > 10) {

                            if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                                return {
                                    results: result, "pagination": {
                                        "more": true
                                    }
                                }
                            } else {
                                return {
                                    results: result, "pagination": {
                                        "more": false
                                    }
                                }
                            }

                        } else {
                            return {
                                results: result, "pagination": {
                                    "more": false
                                }
                            }
                        }
                    }
                }
            }).on('change', function (e) {
                let destination_text = $(e.target).select2('data')[0].text;
                let modalities_pack = [];

                category_hotel.empty();
                category_hotel.removeAttr('disabled');
                pack_hotel.empty();

                create_array_for_product('product_type', form_type_send[0].dataset.type);
                create_array_for_product('destination', e.target.value.split('|')[0]);
                create_array_for_product('destination_id', e.target.value.split("|")[1]);
                create_array_for_product('text_destination', destination_text);
                create_array_for_product('collect_place_text', destination_text);

                category_hotel.select2({
                    theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category'), ajax: {
                        url: `${location.origin}/${locale.locale}/all_service_by_destination`,
                        data: function (params) {
                            return {
                                form_type: form_type_send[0].dataset.type,
                                search: params.term,
                                page: params.page || 1,
                                destination: e.target.value.split("|")[1],
                                whit_modalities: true
                            }
                        },
                        type: "GET",
                        processResults: function (datos, params) {
                            let result = [];
                            categories = datos;
                            if (Object.keys(datos.dealer).length > 0) {
                                Object.entries(datos.dealer).forEach(([key, value]) => {
                                    if (Object.keys(value.services).length > 0) {
                                        let aux = {
                                            text: value.name, children: []
                                        };
                                        Object.entries(value.services).forEach((elem, index) => {
                                            if (elem !== null) {
                                                aux.children.push({
                                                    id: elem[1].code + '|' + value.id,
                                                    text: elem[1].name
                                                });
                                            }
                                        })

                                        result.push(aux);
                                    }
                                });
                                return {
                                    results: result
                                }
                            } else {
                                return {
                                    results: [{
                                        text: "No hay datos para mostrar", children: []
                                    }]
                                }
                            }
                        }
                    }
                }).prop("disabled", false).on('change', function (e) {
                    let selected_option = e.target.value.split('|');
                    let product = selected_option[0]
                    let dealer = selected_option[1]

                    create_array_for_product('dealer', categories.dealer[dealer].code);
                    create_array_for_product('dealer_name', categories.dealer[dealer].name);
                    create_array_for_product('category', product);
                    create_array_for_product('text_category', categories.dealer[dealer].services[product].name);

                    pack_hotel.empty();
                    pack_hotel.removeAttr('disabled');

                    // get_modalities(null, dealer.code, 'code', e.target.value, form_type_send[0].dataset.type).then(gmodality => {
                    //
                    // })

                    date_collect_hotel.removeAttr('disabled');

                    create_array_for_product('day_min_rent', categories.dealer[dealer].services[product].min_reservation_amount);
                    create_array_for_product('day_max_rent', categories.dealer[dealer].services[product].max_reservation_amount);

                    flatpickr("#date_collect_hotel", {
                        altInput: true,
                        altFormat: "d-m-Y",
                        dateFormat: "Y-m-d",
                        disableMobile: "true",
                        disable: date_disabled,
                        minDate: new Date().fp_incr(parseInt(categories.dealer[dealer].services[product].reservation_time)),
                        locale: flatpick_lang
                    });

                    date_collect_hotel.on('change', (e) => {
                        //VERIFICAR TEMPORADA
                        verify_season(e.target.value).then(vs => {
                            let max_date = 0;
                            let min_date = 0;

                            if (vs.length > 0) {
                                max_date = parseInt(vs[0].max) + 1;
                                min_date = parseInt(vs[0].min) + 1;
                            } else {
                                max_date = parseInt(create_array_for_product('day_max_rent', '', 'show')) + 1;
                                min_date = parseInt(create_array_for_product('day_min_rent', '', 'show')) + 1;
                            }
                            date_delivery_hotel.removeAttr('disabled');

                            flatpickr("#date_delivery_hotel", {
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                disableMobile: "true",
                                disable: date_disabled,
                                minDate: new Date(e.target.value).fp_incr(min_date),
                                maxDate: new Date(e.target.value).fp_incr(max_date),
                                locale: flatpick_lang
                            });
                        });
                    })

                    let packs = categories.dealer[dealer].modalities[product];
                    if (packs.length > 0) {
                        $(pack_hotel).append($('<option>').val(0).text(I18n.t('global.main.form.select_pack')));
                        packs.map((child, number) => {
                            let option = $('<option>').val(child.code).text(child.name);
                            option[0].setAttribute('data-adults', child.adults);
                            option[0].setAttribute('data-childrens', child.child);
                            $(pack_hotel).append(option);
                        });

                        pack_hotel.on('change', function (e) {
                            let option_selected = $(this).find('option:selected')[0];
                            create_array_for_product('modality_code', e.target.value);
                            create_array_for_product('amount', 1);
                            create_array_for_product('adult', option_selected.dataset.adults);

                            let count_childrens = Number(option_selected.dataset.childrens);
                            create_array_for_product('children', count_childrens);

                            if (count_childrens > 0) {
                                let cont_cant_hijos = `<div class="show-when-active"><h5>${(count_childrens === 1) ? I18n.t('reservation.side_bar.children_age') : I18n.t('reservation.side_bar.childrens_age')}</h5>`;

                                let i = 1;
                                while (i <= count_childrens) {
                                    cont_cant_hijos += `<div style="display: flex; justify-content: space-between; align-items: center;">
                                                          <div><label for="hotel_hijo_${i}">${I18n.t('reservation.side_bar.children')} ${i}: </label></div>
                                                          <div><input class="hotel_hijo" id="hotel_hijo_${i}" type="number" value="0" min="0" max="14" data-action="change->preferences#hotelAgeChildren" data-total="${count_childrens}"></div>
                                                        </div>
                                                        <span id="span_hotel_hijo_${i}" class="" style="margin-left: 1rem; color: #ef4035; font-size: 0.8em; display: block;">
                                                          <i class="fa fa-info" style="margin-right: 1rem;"></i>
                                                          ${I18n.t('reservation.side_bar.children_info')}
                                                        </span>`;
                                    i++; // Incrementa i en cada iteración
                                }

                                cont_cant_hijos += `</div>`;

                                $('#childrens_age').html(cont_cant_hijos)
                            }
                        })
                    }
                })
            })
            //FIN DE FORMULARIO PARA HOTELES

            //FORMULARIO PARA SERVICIOS
            destination_service.select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_destiny'), ajax: {
                    url: `${location.origin}/${locale.locale}/destination`,
                    dataType: "json",
                    language: "es",
                    data: function (params) {
                        return {
                            form_type: form_type_send[0].dataset.type, search: params.term, page: params.page || 1
                        }
                    },
                    type: 'GET',
                    processResults: function (data, params) {
                        let result = [];
                        params.page = params.page || 1;
                        data.rows.map((elem, key) => {
                            result.push({
                                id: elem.code + '|' + elem.id, text: elem.name
                            })
                        })
                        if (data.total > 10) {

                            if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                                return {
                                    results: result, "pagination": {
                                        "more": true
                                    }
                                }
                            } else {
                                return {
                                    results: result, "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        } else {
                            return {
                                results: result, "pagination": {
                                    "more": false
                                }
                            }
                        }
                    }
                }
            }).on('change', function (e) {
                let destination_text = $(e.target).select2('data')[0].text;
                category_service.empty();
                category_service.removeAttr('disabled');

                create_array_for_product('product_type', form_type_send[0].dataset.type);
                create_array_for_product('destination', e.target.value.split('|')[0]);
                create_array_for_product('destination_id', e.target.value.split("|")[1]);
                create_array_for_product('text_destination', destination_text);
                create_array_for_product('amount', 1);
                create_array_for_product('pax', 1);

                $.ajax({
                    url: `${location.origin}/${sessionStorage.getItem('locale')}/service_by_destination`, data: {
                        destination: e.target.value.split("|")[1], form_type: form_type_send[0].dataset.type
                    }, type: 'GET', success: function (data) {
                        if (data.success) {
                            let dealer = data.dealer;

                            create_array_for_product('dealer', dealer.code);
                            create_array_for_product('dealer_name', dealer.name);
                            create_array_for_product('collect_place_text', destination_text);

                            category_service.select2({
                                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category'), ajax: {
                                    url: `${location.origin}/${locale.locale}/service_by_dealer`,
                                    data: function (params) {
                                        return {
                                            form_type: form_type_send[0].dataset.type,
                                            search: params.term,
                                            page: params.page || 1,
                                            dealer: dealer.code
                                        }
                                    },
                                    type: "GET",
                                    processResults: function (data, params) {
                                        let result = [];
                                        categories = data;
                                        if (data.services.length > 0) {
                                            let aux = {
                                                text: data.dealer_name, children: []
                                            };

                                            data.services.map((child, number) => {
                                                if (child !== null) {
                                                    aux.children.push({
                                                        id: child.code, text: child.name
                                                    });
                                                }
                                            })
                                            result.push(aux);

                                            return {
                                                results: result
                                            }
                                        } else {
                                            return {
                                                results: [{
                                                    text: "No hay datos para mostrar", children: []
                                                }]
                                            }
                                        }
                                    }
                                }
                            }).prop("disabled", false).on('change', function (e) {
                                create_array_for_product('category', e.target.value);
                                create_array_for_product('text_category', $(e.target).select2('data')[0].text);

                                get_modalities(null, dealer.code, 'code', e.target.value, form_type_send[0].dataset.type).then(gmodality => {
                                })

                                categories_details(e.target.value).then(category_details => {
                                    amount_catering_services.attr('data-min', category_details.min_reservation_amount);
                                    amount_catering_services.attr('data-max', category_details.max_reservation_amount);
                                    amount_catering_services.removeAttr('disabled');
                                    date_collect_service.removeAttr('disabled');
                                    time_collect_service.removeAttr('disabled');

                                    create_array_for_product('day_min_rent', category_details.min_reservation_amount);
                                    create_array_for_product('day_max_rent', category_details.max_reservation_amount);

                                    flatpickr("#date_collect_service", {
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        disableMobile: "true",
                                        disable: date_disabled,
                                        minDate: new Date().fp_incr(parseInt(category_details.reservation_time)),
                                        locale: flatpick_lang
                                    });

                                    flatpickr("#time_collect_service", {
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                        altFormat: "H:i",
                                        time_24hr: true,
                                        locale: flatpick_lang,
                                        disableMobile: "true"
                                    })
                                })
                            })
                        } else {
                            toastr.error(data.msg);
                        }
                    }
                })
            })
            //FIN DE FORMULARIO PARA SERVICIOS

            // FORMULARIO PARA SHUTTLE
            select_destination_shuttle.select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_destiny'), ajax: {
                    url: `${location.origin}/${locale.locale}/destination`,
                    dataType: "json",
                    language: "es",
                    data: function (params) {
                        return {
                            form_type: form_type_send[0].dataset.type, search: params.term, page: params.page || 1
                        }
                    },
                    type: 'GET',
                    processResults: function (data, params) {
                        let result = [];
                        params.page = params.page || 1;
                        data.rows.map((elem, key) => {
                            result.push({
                                id: elem.code + '|' + elem.id, text: elem.name
                            })
                        })
                        if (data.total > 10) {
                            if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                                return {
                                    results: result, "pagination": {
                                        "more": true
                                    }
                                }
                            } else {
                                return {
                                    results: result, "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        } else {
                            return {
                                results: result, "pagination": {
                                    "more": false
                                }
                            }
                        }
                    }
                }
            }).on('change', function (e) {
                let destination_text = $(e.target).select2('data')[0].text;

                create_array_for_product('product_type', form_type_send[0].dataset.type);
                create_array_for_product('destination', e.target.value.split('|')[0]);
                create_array_for_product('destination_id', e.target.value.split("|")[1]);
                create_array_for_product('text_destination', destination_text);

                $.ajax({
                    url: `${location.origin}/${sessionStorage.getItem('locale')}/service_by_destination`, data: {
                        destination: e.target.value.split("|")[1], form_type: form_type_send[0].dataset.type
                    }, type: 'GET', success: function (data) {
                        if (data.success) {

                            let product = data.product;
                            let dealer = data.dealer;
                            // let contract = data.contract;

                            create_array_for_product('dealer', dealer.code);
                            create_array_for_product('dealer_name', dealer.name);
                            create_array_for_product('collect_place_text', destination_text);
                            create_array_for_product('category', product.code);
                            create_array_for_product('text_category', product.name);
                            // create_array_for_product ('contract_code_number', contract.code_number);
                            // create_array_for_product ('contract_code', contract.code);

                            get_modalities(null, dealer.code, 'code', product.code, form_type_send[0].dataset.type).then(gmodality => {

                            })

                            amount_shuttle.attr('data-min', product.min_reservation_amount);
                            amount_shuttle.attr('data-max', product.max_reservation_amount);
                            amount_shuttle.removeAttr('disabled');
                            date_collect_shuttle.removeAttr('disabled');

                            create_array_for_product('day_min_rent', product.min_reservation_amount);
                            create_array_for_product('day_max_rent', product.max_reservation_amount);

                            flatpickr("#date_collect_shuttle", {
                                altInput: true,
                                altFormat: "d-m-Y",
                                dateFormat: "Y-m-d",
                                disableMobile: "true",
                                disable: date_disabled,
                                minDate: new Date().fp_incr(parseInt(product.reservation_time)),
                                locale: flatpick_lang
                            });
                        } else {
                            toastr.error(data.msg);
                        }
                    }
                })
            })
            //FIN DE FORMULARIO PARA SHUTTLE

            //FORMULARIO PARA EL BUSTOUR bustour
            select_destination_bustour.select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_destiny'), ajax: {
                    url: `${location.origin}/${locale.locale}/destination`,
                    dataType: "json",
                    language: "es",
                    data: function (params) {
                        return {
                            form_type: form_type_send[0].dataset.type, search: params.term, page: params.page || 1
                        }
                    },
                    type: 'GET',
                    processResults: function (data, params) {
                        let result = [];
                        params.page = params.page || 1;
                        data.rows.map((elem, key) => {
                            result.push({
                                id: elem.code + '|' + elem.id, text: elem.name
                            })
                        })
                        if (data.total > 10) {
                            if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                                return {
                                    results: result, "pagination": {
                                        "more": true
                                    }
                                }
                            } else {
                                return {
                                    results: result, "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        } else {
                            return {
                                results: result, "pagination": {
                                    "more": false
                                }
                            }
                        }
                    }
                }
            }).on('change', function (e) {
                let destination_text = $(e.target).select2('data')[0].text;
                category_bustour.empty();
                category_bustour.removeAttr('disabled');

                create_array_for_product('product_type', form_type_send[0].dataset.type);
                create_array_for_product('destination', e.target.value.split('|')[0]);
                create_array_for_product('destination_id', e.target.value.split("|")[1]);
                create_array_for_product('text_destination', destination_text);

                $.ajax({
                    url: `${location.origin}/${sessionStorage.getItem('locale')}/service_by_destination`, data: {
                        destination: e.target.value.split("|")[1], form_type: form_type_send[0].dataset.type
                    }, type: 'GET', success: function (data) {
                        if (data.success) {
                            let dealer = data.dealer;
                            let contract = data.contract;

                            create_array_for_product('dealer', dealer.code);
                            create_array_for_product('dealer_name', dealer.name);
                            create_array_for_product('collect_place_text', destination_text);

                            category_bustour.select2({
                                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category'), ajax: {
                                    url: `${location.origin}/${locale.locale}/service_by_dealer`,
                                    data: function (params) {
                                        return {
                                            form_type: form_type_send[0].dataset.type,
                                            search: params.term,
                                            page: params.page || 1,
                                            dealer: dealer.code
                                        }
                                    },
                                    type: "GET",
                                    processResults: function (data, params) {
                                        let result = [];
                                        categories = data;
                                        if (data.services.length > 0) {
                                            let aux = {
                                                text: data.dealer_name, children: []
                                            };

                                            data.services.map((child, number) => {
                                                if (child !== null) {
                                                    aux.children.push({
                                                        id: child.code, text: child.name
                                                    });
                                                }
                                            })
                                            result.push(aux);

                                            return {
                                                results: result
                                            }
                                        } else {
                                            return {
                                                results: [{
                                                    text: "No hay datos para mostrar", children: []
                                                }]
                                            }
                                        }
                                    }
                                }
                            }).prop("disabled", false).on('change', function (e) {
                                create_array_for_product('category', e.target.value);
                                create_array_for_product('text_category', $(e.target).select2('data')[0].text);

                                get_modalities(null, dealer.code, 'code', e.target.value, form_type_send[0].dataset.type).then(gmodality => {
                                })

                                categories_details(e.target.value).then(category_details => {
                                    // setSessionVar('day_delay_rent', category_details.reservation_time, 'add', false);
                                    // setSessionVar('day_min_rent', category_details.min_length_reservation, 'add', false);
                                    // setSessionVar('day_max_rent', category_details.max_length_reservation, 'add', false);
                                    // setSessionVar('category_description', category_details.description, 'add', false);
                                    // setSessionVar('use_same_hours', category_details.same_hours, 'add', false);

                                    amount_bustour.attr('data-min', category_details.min_reservation_amount);
                                    amount_bustour.attr('data-max', category_details.max_reservation_amount);
                                    amount_bustour.removeAttr('disabled');
                                    date_collect_bustour.removeAttr('disabled');

                                    create_array_for_product('day_min_rent', category_details.min_reservation_amount);
                                    create_array_for_product('day_max_rent', category_details.max_reservation_amount);

                                    flatpickr("#date_collect_bustour", {
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        disableMobile: "true",
                                        disable: date_disabled,
                                        minDate: new Date().fp_incr(parseInt(category_details.reservation_time)),
                                        locale: flatpick_lang
                                    });
                                })
                            })
                        } else {
                            toastr.error(data.msg);
                        }
                    }
                })
            })
            //FIN DE FORMULARIO PARA bustour

            // FORMULARIO PARA EL BUS TRAVEL
            has_book_owner.on('change', function (e) {
                if ($(this).is(':checked')) {
                    user_data_as_comment();
                } else {
                    create_array_for_product('is_bus_owner', false);
                    let dialog_user_data = bootbox.dialog({
                        title: 'Datos del Responsable', message: `<p class='text-danger small'>(*) Campos obligatorios</p><br>
                                    <div id="owner_bus_form" class="row">
                                        <div class="col-12 mb-2">
                                         <input id="user_username" type="text" placeholder="* Nombre" class="form-control required"> 
                                        </div>
                                        <div class="col-12 mb-2">
                                         <input id="user_lastname" type="text" placeholder="* Apellidos" class="form-control required"> 
                                        </div>
                                        <div class="col-12 mb-2">
                                         <input id="user_passport" type="text" placeholder="* Pasaporte 0 Carnet de identidad" class="form-control required"> 
                                        </div>
                                         <div class="col-12 mb-2">
                                         <input id="user_email"  type="email" placeholder="* Correo Electrónico" class="form-control required">
                                         <small class="text-danger"> </small> 
                                        </div>
                                        <div class="col-12 mb-2">
                                         <input id="user_phone" type="text" placeholder="* Ciudad" class="form-control required"> 
                                        </div>
                                        <div class="col-12 mb-2">
                                         <input id="user_address" type="text" placeholder="* Dirección" class="form-control required"> 
                                        </div>
                                    </div>
                              `, size: 'small', buttons: {
                            cancel: {
                                label: "Cancelar",
                                className: 'btn-secondary',
                                callback: function (e) {
                                    has_book_owner.prop('checked', true).trigger('change');
                                    create_array_for_product('is_bus_owner', true);
                                }
                            },
                            ok: {
                                label: "Confirmar",
                                className: 'btn-success',
                                callback: function (e) {
                                    let count = 0;
                                    let form_required = $('#owner_bus_form').find('.required');
                                    form_required.map((index, element) => {
                                        if (element.value.isEmpty()) {
                                            count += 1;
                                            $(element)[0].classList.remove('is-valid');
                                            $(element)[0].classList.add('is-invalid');
                                        } else {
                                            $(element)[0].classList.add('is-valid');
                                            $(element)[0].classList.remove('is-invalid');
                                        }
                                    });

                                    validate_emails(document.getElementById('user_email').value).then(success => {
                                        if (success) {
                                            if (count > 0) {
                                                return false;
                                            } else {
                                                $('button.bootbox-close-button').trigger('click');
                                                responsible_value = `Nombre: ${document.getElementById('user_username').value} 
                                                                            <br> Apellidos: ${document.getElementById('user_lastname').value} 
                                                                            <br> CI ó Pasaporte: ${document.getElementById('user_passport').value} 
                                                                            <br> Email: ${document.getElementById('user_email').value} 
                                                                            <br> Teléfono: ${document.getElementById('user_phone').value} 
                                                                            <br> Dirección: ${document.getElementById('user_address').value}`;
                                                make_comment();
                                                // resolve({
                                                //     username: document.getElementById('user_username').value,
                                                //     lastname: document.getElementById('user_lastname').value,
                                                //     email: document.getElementById('user_email').value,
                                                //     passport: document.getElementById('user_passport').value,
                                                //     country_code: document.getElementById('user_country_code').value.toLowerCase(),
                                                //     country_city: document.getElementById('user_city').value,
                                                //     address: document.getElementById('user_address').value
                                                //     user_phone: document.getElementById('user_phone').value
                                                // })


                                            }
                                        } else {
                                            let email_user_form = document.getElementById('user_email');
                                            email_user_form.classList.add('is-invalid');
                                            email_user_form.classList.remove('is-valid');
                                        }
                                    })

                                    return false;
                                }
                            }
                        }
                    })
                    setTimeout(() => {
                        $('.bootbox.modal')[0].removeAttribute('tabindex');
                    }, 500);
                }
            })

            out_bus.select2({
                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_destiny'), ajax: {
                    url: `${location.origin}/${locale.locale}/destination`,
                    dataType: "json",
                    language: "es",
                    data: function (params) {
                        return {
                            form_type: form_type_send[0].dataset.type, search: params.term, page: params.page || 1
                        }
                    },
                    type: 'GET',
                    processResults: function (data, params) {
                        let result = [];
                        params.page = params.page || 1;
                        data.rows.map((elem, key) => {
                            result.push({
                                id: elem.code + '|' + elem.id, text: elem.name
                            })
                        })
                        if (data.total > 10) {

                            if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                                return {
                                    results: result, "pagination": {
                                        "more": true
                                    }
                                }
                            } else {
                                return {
                                    results: result, "pagination": {
                                        "more": false
                                    }
                                }
                            }
                        } else {
                            return {
                                results: result, "pagination": {
                                    "more": false
                                }
                            }
                        }
                    }
                }
            }).on('change', function (e) {
                let destination_text = $(e.target).select2('data')[0].text;
                category_bus.empty();
                destination_bus.empty().prop('disabled', true);
                category_bus.removeAttr('disabled');
                bus_location.removeAttr('disabled');
                date_collect_bus.empty();
                date_collect_bus.prop('disabled', true);

                create_array_for_product('product_type', form_type_send[0].dataset.type);
                create_array_for_product('destination', e.target.value.split('|')[0]);
                create_array_for_product('destination_id', e.target.value.split("|")[1]);
                create_array_for_product('text_destination', destination_text);

                $.ajax({
                    url: `${location.origin}/${sessionStorage.getItem('locale')}/service_by_destination`, data: {
                        destination: e.target.value.split("|")[1], form_type: form_type_send[0].dataset.type
                    }, type: 'GET', success: function (data) {
                        if (data.success) {

                            let dealer = data.dealer;
                            let contract = data.contract;

                            create_array_for_product('dealer', dealer.code);
                            create_array_for_product('dealer_name', dealer.name);
                            create_array_for_product('collect_place_text', destination_text);
                            create_array_for_product('amount', 1);
                            create_array_for_product('pax', 1);

                            // create_array_for_product ('contract_code_number', contract.code_number);
                            // create_array_for_product ('contract_code', contract.code);

                            category_bus.select2({
                                theme: "bootstrap", placeholder: I18n.t('global.main.form.select_category'), ajax: {
                                    url: `${location.origin}/${locale.locale}/service_by_dealer`,
                                    data: function (params) {
                                        return {
                                            form_type: form_type_send[0].dataset.type,
                                            search: params.term,
                                            page: params.page || 1,
                                            dealer: dealer.code
                                        }
                                    },
                                    type: "GET",
                                    processResults: function (data, params) {
                                        let result = [];
                                        categories = data;
                                        if (data.services.length > 0) {
                                            let aux = {
                                                text: data.dealer_name, children: []
                                            };

                                            data.services.map((child, number) => {
                                                if (child !== null) {
                                                    aux.children.push({
                                                        id: child.code, text: child.name
                                                    });
                                                }
                                            })
                                            result.push(aux);

                                            return {
                                                results: result
                                            }
                                        } else {
                                            return {
                                                results: [{
                                                    text: "No hay datos para mostrar", children: []
                                                }]
                                            }
                                        }
                                    }
                                }
                            }).prop("disabled", false).on('change', function (e) {
                                create_array_for_product('category', e.target.value);
                                create_array_for_product('text_category', $(e.target).select2('data')[0].text);

                                get_modalities(null, dealer.code, 'code', e.target.value, form_type_send[0].dataset.type).then(gmodality => {

                                })

                                categories_details(e.target.value).then(category_details => {
                                    date_collect_bus.removeAttr('disabled');
                                    destination_bus.empty();
                                    destination_bus.removeAttr('disabled');
                                    time_collect_bus.removeAttr('disabled');

                                    create_array_for_product('day_min_rent', category_details.min_reservation_amount);
                                    create_array_for_product('day_max_rent', category_details.max_reservation_amount);

                                    flatpickr("#date_collect_bus", {
                                        altInput: true,
                                        altFormat: "d-m-Y",
                                        dateFormat: "Y-m-d",
                                        disableMobile: "true",
                                        disable: date_disabled,
                                        minDate: new Date().fp_incr(parseInt(category_details.reservation_time)),
                                        maxDate: new Date().fp_incr(180),
                                        locale: flatpick_lang
                                    });

                                    flatpickr("#time_collect_bus", {
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i",
                                        altFormat: "H:i",
                                        time_24hr: true,
                                        locale: flatpick_lang,
                                        disableMobile: "true"
                                    });

                                    $.ajax({
                                        url: location.origin + '/modalities_by_product', data: {
                                            dealer: create_array_for_product('dealer', '', 'show'),
                                            product: create_array_for_product('category', '', 'show')
                                        }, type: 'GET', success: function (data_modalities) {
                                            if (parseInt(data_modalities.count) > 1) {
                                                let data_result = [{
                                                    id: 0, text: I18n.t('global.main.form.select_destiny')
                                                }];

                                                data_modalities.data.map((elem, index) => {

                                                    data_result.push({
                                                        id: elem.code + "|" + elem.name + "|" + elem.id, text: elem.name
                                                    })
                                                })

                                                destination_bus.select2({
                                                    theme: "bootstrap",
                                                    placeholder: I18n.t('global.main.form.select_modality'),
                                                    data: data_result
                                                }).prop("disabled", false).on('change', function (e) {
                                                    let modality_selected = e.target.value.split("|");
                                                    create_array_for_product('modality_code', modality_selected[0])
                                                    create_array_for_product('modalities_name', modality_selected[1])
                                                })
                                            }
                                        }
                                    })
                                })

                                date_collect_bus.on('change', (e) => {
                                    verify_season(e.target.value).then(vs => {
                                        let max_date = 31;
                                        let min_date = 0;

                                        if (vs.length > 0) {
                                            // max_date = parseInt (vs[0].max) + 1;
                                            min_date = parseInt(vs[0].min) + 1;
                                        } else {
                                            // max_date = parseInt (create_array_for_product ('day_max_rent', '','show')) + 1;
                                            min_date = parseInt(create_array_for_product('day_min_rent', '', 'show')) + 1;
                                        }

                                        $('#date_delivery_bus').prop('disabled', false);
                                        flatpickr("#date_delivery_bus", {
                                            altInput: true,
                                            altFormat: "d-m-Y",
                                            dateFormat: "Y-m-d",
                                            disableMobile: "true",
                                            disable: date_disabled,
                                            minDate: new Date(e.target.value).fp_incr(min_date),
                                            maxDate: new Date(e.target.value).fp_incr(max_date),
                                            locale: flatpick_lang
                                        });

                                        if (sessionStorage.getItem('use_same_hours') !== 'true') {
                                            $('#time_delivery').prop('disabled', false);
                                        }
                                    });
                                })
                                time_collect_bus.on('change', (e) => {
                                    time_delivery_bus[0].value = e.target.value;
                                    create_array_for_product('time_delivery', e.target.value.value);
                                })
                            })
                        } else {
                            toastr.error(data.msg);
                        }
                    }
                })
            })
            // FIN DE FORMULARIO PARA EL BUS TRAVEL
        })

        unblockBody ();
    }

    set_form_type(e) {
        let data = e.target.dataset;
        if (!data.hasOwnProperty('formType')) {
            parent = e.target.parentNode;
            if (parent.dataset.hasOwnProperty('formType')) {
                data = parent.dataset
            } else {
                let element = e.target.viewportElement
                data = element.parentNode.dataset
            }
        }

        $('#search_form_type')[0].setAttribute('data-type', data.formType);
        create_array_for_product('', '', 'clear');
        create_array_for_product('drivers', 0);
        create_array_for_product('supplement_insurance', 0);
        create_array_for_product('product_type', data.formType, 'add');

        if (data.hasOwnProperty('container')) {
            let selector_form = $('.combined_product_by_type');

            if (selector_form.length > 0) {
                selector_form.map((index, elem) => {
                    elem.classList.add('ocultar');
                })
            }

            $(`#${data.show}`)[0].classList.remove('ocultar');
        }

        if (data.formType === 'bus') {
            user_data_as_comment();
        }
    }

    evaluate(e) {
        let element = e.target.dataset;
        let min = parseInt(element.min);
        let max = parseInt(element.max);
        let helper = $(e.target.parentNode).find('.helper-text')[0];
        let block = $(`#${element.block}`);

        if (parseInt(e.target.value) < min) {
            e.target.classList.add('is-invalid');
            if (helper !== undefined) {
                helper.innerHTML = `<span class='text-danger'>El valor del campo debe estar entre ${min} y ${max}</span>`;
            }
            block.prop('disabled', true);
            e.target.value = parseInt(min);
            create_array_for_product('amount', min);
        } else {
            if (parseInt(e.target.value) > max) {
                e.target.classList.add('is-invalid');
                if (helper !== undefined) {
                    helper.innerHTML = `<span class='text-danger'>El valor del campo debe estar entre ${min} y ${max}</span>`;
                }
                block.prop('disabled', true);
                e.target.value = parseInt(max);
                create_array_for_product('amount', max);
            } else {
                e.target.classList.remove('is-invalid');
                if (helper !== undefined) {
                    helper.innerHTML = '';
                }
                block.removeAttr('disabled');

                create_array_for_product('pax', e.target.value);
                create_array_for_product('amount', e.target.value);
            }
        }

        if (element.hasOwnProperty('compare') && element.compare === 'true') {
            let element_compare = $(`#${element.evaluate}`);
            let helper_compare = $(`#${element.helper}`);

            if (element_compare.length > 0) {
                if ((parseInt(e.target.value) + parseInt(element_compare.val())) > parseInt(element.evaluateMax)) {
                    e.target.classList.add('is-invalid');
                    element_compare.addClass('is-invalid');

                    element_compare.val(parseInt(element.evaluateMax) - parseInt(e.target.value));

                    if (helper_compare.length > 0) {
                        helper_compare[0].innerHTML = "<span class='text-danger'>Solo permite hasta " + element.evaluateMax + "</span>";
                    }
                } else {
                    e.target.classList.remove('is-invalid');
                    element_compare.removeClass('is-invalid');
                    if (helper_compare.length > 0) {
                        helper_compare[0].innerHTML = "";
                    }
                }
            }
        }

        // if (create_array_for_product('product_type', '', 'show') === 'hotel') {
        //     let amount = ((parseInt(e.target.value) + parseInt($(`#${element.evaluate}`).val())) / 3).toFixed(1);
        //     let evaluate_amount = amount.split('.');
        //     if (parseInt(evaluate_amount[1]) < 5 && parseInt(evaluate_amount[0]) <= 0) {
        //         evaluate_amount[0] = 1;
        //     } else {
        //         if (parseInt(evaluate_amount[1]) === 0 && parseInt(evaluate_amount[0]) > 0) {
        //             evaluate_amount[0] = parseInt(evaluate_amount[0]);
        //         } else {
        //             evaluate_amount[0] = parseInt(evaluate_amount[0]) + 1;
        //         }
        //     }
        //     create_array_for_product('amount', evaluate_amount[0]);
        // }
    }

    toogleClass(e) {
        let dataset = e.target.dataset;
        let element = $(`#${dataset.element}`)[0];
        element.classList.toggle(dataset.classtoggle);

        setTimeout(() => {
            let body_block = $('html, body');
            if (element.classList.contains('active')) {
                body_block.css({'overflow': 'hidden'});
            } else {
                body_block.css({'overflow': 'visible'});
            }
        }, 200)
    }

    hotelAgeChildren(e) {
        let total_hijos = Number(e.target.dataset.total);
        let total_hijos_text = '';

        if (total_hijos === 1) {
            total_hijos_text = "1 niño";
        } else {
            total_hijos_text = `${total_hijos} niños`;
        }

        let text_age_comment = `Esta reserva tiene un total de ${total_hijos_text}. Desglose: \n\n`;
        let i = 1;
        while (i <= total_hijos) {
            let age_text_age = Number($(`#hotel_hijo_${i}`).val());
            let age_text_age_text = '';

            if (age_text_age === 0) {
                age_text_age_text = "menos de 1 año";
                $(`#span_hotel_hijo_${i}`).css("display", "block");
            } else if (age_text_age === 1) {
                age_text_age_text = "1 año";
                $(`#span_hotel_hijo_${i}`).css("display", "none");
            } else {
                age_text_age_text = `${age_text_age} años`;
                $(`#span_hotel_hijo_${i}`).css("display", "none");
            }

            text_age_comment += `Niño ${i} tiene ${age_text_age_text}.\n`;
            i++;
        }

        /*$('#childrens_age').append(text_age_comment);*/

        create_array_for_product('comment', text_age_comment);
    }

    show_hide_element (e) {
        let hide = $ ('#' + e.target.dataset.hide);
        let show = $ ('#' + e.target.dataset.show);

        hide.addClass ('ocultar');
        show.removeClass ('ocultar');
    }
}